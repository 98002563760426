
/* eslint-disable vue/no-dupe-keys */
import {
  ref,
  onMounted,
  getCurrentInstance,
  watch,
  reactive,
  defineComponent
} from 'vue'
import {
  useStore
} from 'vuex'
import moment from 'moment'
import {
  departureProcessUseCase,
  deliveryReceiptUsecase,
  labelUseCase,
  labelAgentUseCase
} from '@/domain/usecase'
import {
  useRouter,
  useRoute
} from 'vue-router'
import {
  cloneDeep
} from 'lodash'
import {
  encryptDecriptMethods
} from '@/plugins/encryptDecriptMethods'
import { VueEternalLoading, LoadAction } from '@ts-pro/vue-eternal-loading'

export default defineComponent({
  name: 'DialogDetailResi',
  components: {
    VueEternalLoading
  },
  props: {
    isShowDialog: {
      default: false
    },
    data: {
      default: () => null
    }
  },
  emits: ['hideDialog', 'reloadData'],
  setup(props, {
    emit
  }) {
    const modules = 'deliveryItem'
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const app = getCurrentInstance()
    const {
      $toast,
      $confirm,
      $strInd,
      $socketHub
    } = app!.appContext.config.globalProperties
    const dialogDetailResi = ref(true)
    const Id = () => encryptDecriptMethods.decrypt(route.params.id)
    const data = ref({}) as any
    const groupLabel = ref([])
    const labelOption = ref([]) as any
    const labelIdSelect = ref(null) as any
    const labelInput = ref('') as any
    const op = ref()
    const sourceSearch = ref(null) as any
    const isLoadingLabel = ref(false)
    const label = ref([]) as any
    const dataItemsSticker = ref([]) as any
    const stickerOption = ref([]) as any
    const stickerOptionDefault = ref([]) as any
    const filtersLabel = reactive({
      search: ''
    })
    let action: LoadAction
    const isInitial = ref(false)/** variable yang digunakan untuk mereset state menggunakan plugin vue-eternal-loading */
    const pageNumber = ref(1)
    const tempLengthOfList = ref(0)
    const profileAgent = store.getters['appActiveUser/getAgent']

    const schedulingResi = () => {
      if (label.value.length > 0 && label.value.length === data.value.TransPengirimanHd.TotalColly) {
        const mapItemsSticker = dataItemsSticker.value.map((item: any) => ({
          Id: item.stickerId,
          Stock: item.value
        }))
        const mapLabels = label.value.map((item: any) => ({
          Id: item.value
        }))
        const dataSend = {
          TransPengirimanHdId: data.value.TransPengirimanHdId,
          Stikers: mapItemsSticker,
          Labels: mapLabels
        }
        departureProcessUseCase.assignLabelsAndStickers(dataSend).then((response) => {
          if (!response.error) {
            $toast.add({
              severity: 'success',
              detail: response.result.detail ?? response.result.Detail,
              group: 'bc',
              life: 3000
            })
            router.replace({
              name: 'departure-package'
            })
            $socketHub.emit('reloadDataScheduling')
          } else {
            $toast.add({
              severity: 'error',
              detail: response.message,
              group: 'bc',
              life: 3000
            })
          }
        })
      } else if (label.value.length < data.value.TransPengirimanHd.TotalColly) {
        $toast.add({
          severity: 'error',
          detail: 'Jumlah label harus sesuai dengan jumlah koli',
          group: 'bc',
          life: 3000
        })
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Pilih label terlebih dahulu',
          group: 'bc',
          life: 3000
        })
      }
    }

    // const stickerFilter = async () => {
    //   const tempItemsSticker = await cloneDeep(dataItemsSticker.value)
    //   const dataMap = await tempItemsSticker.map((x: any) => x.stickerId)
    //   const tempData = await cloneDeep(stickerOption.value.filter((item: any) => !dataMap.includes(item.value)))
    //   stickerOption.value = tempData
    // }

    const stickerFilter = () => {
      const dataMap = store.state[modules].itemsSticker.map((x: any) => x.stickerId)
      const data = stickerOptionDefault.value.filter((item: any) => !dataMap.includes(item.value))
      stickerOption.value = data
    }

    const fetchLabel = async (search: any = null) => {
      isLoadingLabel.value = true
      const result = await deliveryReceiptUsecase.getSelectDataLabel({
        filter: `AgenId eq ${data.value.TransPengirimanHd.AgenAsalId} and LabelId eq ${labelIdSelect.value} and IsPicked eq false and TransPengirimanHdId eq null`,
        sorting: 'KodeLabel asc',
        expand: '&$expand=Label($select=Id,Kode,Warna)',
        /* eslint-disable-next-line object-shorthand */
        search: search
      })
      // mappingLabel(result.result)
      if (!result.error) {
        labelOption.value = await result.result.map((x: any) => ({
          color: (x.Label) ? x.Label.Warna : 'grey',
          label: x.KodeLabel,
          value: x.Id,
          labelId: x.LabelId
        }))
      }
      isLoadingLabel.value = false
    }

    const fetchLabelV2 = async (search: any = '') => {
      isLoadingLabel.value = true
      filtersLabel.search = search === '' ? search : sourceSearch.value
      const result = await labelAgentUseCase.getStockLabelAgent(profileAgent?.id, { ...filtersLabel, filterField: ['kodeLabel'], custom: [['isPicked', '=', 'false'], ['labelId', '=', labelIdSelect.value], ['pageNumber', '=', pageNumber.value], ['orderBy', '=', 'createdAt desc, kodeLabel asc']] })
      tempLengthOfList.value = result.result.Data.length

      if (!result.error) {
        const labelOptionTemp = await result.result.Data.map((x: any) => ({
          color: (x.LabelWarna) ? x.LabelWarna : 'grey',
          label: x.KodeLabel,
          value: x.Id,
          labelId: x.LabelId
        }))
        labelOptionTemp.forEach((x: any) => labelOption.value.push(x))
      }

      isLoadingLabel.value = false
    }

    const fetchSticker = async () => {
      const result = await deliveryReceiptUsecase.getSelectDataSticker(data.value.TransPengirimanHd.AgenAsalId)
      stickerOption.value = result.result.map((x: any) => ({
        label: `${x.Sticker.Kode} ${x.Sticker.Nama}`,
        value: x.Id
      }))
      stickerOptionDefault.value = stickerOption.value
      stickerFilter()
    }

    const dataDetail = () => {
      store.dispatch('showLoading')
      departureProcessUseCase.getDataForm(Id(), {
        expand: '$expand=TransPengirimanHd($expand=AgenAsal($select=Alamat,Kode,Nama),AgenTujuan($select=Alamat,Kode,Nama);$select=Id,AgenAsalId,AgenTujuanId,AlamatPenerima,AlamatPengirim,HpPenerima,HpPengirim,JenisPembayaran,Resi,TipePengiriman,TotalBerat,TotalColly,LastUpdate,KategoriBarang,NamaPengirim,StatusPengirimanId,TransJadwalHdId)',
        select: '&$select=Id,TransPengirimanHdId,KurirTipePengirimanId,KurirMsUserId,IsActive',
      }).then((response) => {
        if (!response.error) {
          data.value = response.result
          fetchLabelV2()
          fetchSticker()
        } else {
          $toast.add({
            severity: 'error',
            detail: $strInd.toast.errorDetailData,
            group: 'bc',
            life: 3000
          })
          router.back()
        }
        store.dispatch('hideLoading')
      })
    }

    const goBack = () => {
      router.replace({
        name: 'scheduling-departure-package-step-1',
        params: {
          id: route.params.id
        }
      })
    }

    const getColorLabel = async () => {
      const result = await labelUseCase.getAll({
        sorting: 'Kode asc',
        select: '&$select=Id,Kode,Nama,Warna'
      })

      groupLabel.value = result.result.map((x: any) => ({
        color: x.Warna,
        label: x.Nama,
        value: x.Id
      }))
    }

    const getColorLabelV2 = async () => {
      filtersLabel.search = profileAgent.id
      const result = await labelUseCase.getAll({ ...filtersLabel, filterField: ['agenId'] })

      if (!result.error) {
        groupLabel.value = result.result.Data.map((x: any) => ({
          color: x.Warna,
          label: x.Nama,
          value: x.Id
        }))
      }
    }

    const changeValueLabelInput = async (val: any, evt: any) => {
      labelOption.value = []
      pageNumber.value = 1
      await op.value.toggle(evt)
      // await op.value.show()
      sourceSearch.value = await val
      await fetchLabelV2(sourceSearch.value)
    }

    const searchBtnLabel = async (evt: any) => {
      pageNumber.value = 1
      sourceSearch.value = labelInput.value
      if (labelIdSelect.value && sourceSearch.value) {
        await fetchLabelV2(sourceSearch.value)
        await op.value.toggle(evt)
      }
    }

    const deleteLabel = async (val: any) => {
      label.value.splice(val, 1)
    }

    const clickPlace = async (val: any) => {
      if (label.value.length >= data.value.TransPengirimanHd.TotalColly) {
        $toast.add({
          severity: 'error',
          detail: `Pilhan Label Maksimal ${data.value.TransPengirimanHd.TotalColly}`,
          group: 'bc',
          life: 3000
        })
      } else {
        // eslint-disable-next-line no-lonely-if
        if (label.value.map((x: any) => x.value).indexOf(val.value) > -1) {
          $toast.add({
            severity: 'error',
            summary: 'Label Tidak Boleh Sama',
            detail: `Label ${val.label} Telah Digunakan`,
            group: 'bc',
            life: 3000
          })
        } else {
          await label.value.push(val)
        }
      }
    }

    const tambahSticker = () => {
      dataItemsSticker.value.push({
        stickerId: 0,
        value: 0,
        stickerOption: stickerOption.value
      })
    }

    const deleteSticker = (index: any) => {
      dataItemsSticker.value.splice(index, 1)
    }

    /** Menggunakan load data untuk plugin pagination */
    const loadData = async ({ loaded, noMore }: LoadAction) => {
      console.log('aaa', tempLengthOfList.value)
      if (tempLengthOfList.value >= 10) {
        pageNumber.value += 1
        fetchLabelV2().then((x: any) => {
          if (tempLengthOfList.value < 10) {
            console.log('<10', tempLengthOfList.value)
            /** menggunakan fungsi unutuk mereset state data kosong type dri interface plugin vue-eternal-loading */
            noMore()
          } else {
            /** menggunakan fungsi unutuk load state type dri interface plugin vue-eternal-loading */
            loaded()
          }
        })
      } else {
        noMore()
      }
    }

    watch([dataItemsSticker.value], ([newVal2], [oldVal2]) => {
      // store.dispatch(`${modules}/setBiayaTambahan`, newVal)
      // store.dispatch(`${modules}/calculateTotalDiskon`, newVal)
      if (newVal2) {
        store.dispatch(`${modules}/setItemsSticker`, newVal2)
        stickerFilter()
      }
    })

    onMounted(() => {
      dataDetail()
      getColorLabelV2()
    })
    return {
      dialogDetailResi,
      store,
      app,
      moment,
      schedulingResi,
      goBack,
      data,
      getColorLabelV2,
      labelIdSelect,
      op,
      searchBtnLabel,
      changeValueLabelInput,
      labelInput,
      labelOption,
      label,
      deleteLabel,
      clickPlace,
      dataItemsSticker,
      tambahSticker,
      deleteSticker,
      groupLabel,
      isLoadingLabel,
      loadData,
      isInitial
    }
  }
})
